import React from 'react'
import CheckoutFoarm from './CheckoutForm/CheckoutFoarm'
import Navbar from 'components/Navbar/Navbar';
import Footer from 'components/Footer/Footer';

const BillingAdress = () => {
  return (
    <>
    <Navbar/>
      <CheckoutFoarm/>
    <Footer/>
    </>
  )
}

export default BillingAdress
