import React from "react";
import styles from "./Styles.module.scss";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { setPlaceOrder } from "reduxStore/slice/userSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { clearCart } from "reduxStore/slice/addToCartSlice";

const OrderPlaced = () => {
  const dispatch = useDispatch();
  const addToCartData = useSelector((value) => value.addToCart);
  const pushDataToDataLayer = () => {
    const products = addToCartData.LineItems.map((item) => ({
      id: item.ProductId,
      name: item.ProductName,
      price: item.Price,
      quantity: item.Quantity,
    }));
    const total = addToCartData.TotalPrice;
    window.dataLayer.push({
      event: "completepurchase",
      ecommerce: {
        checkout: {
          actionField: { step: 1 },
          products: products,
          totalPrice: total,
        },
      },
    });
  };
  const clearCartWithOrder = () => {
    dispatch(clearCart());
  };
  useEffect(() => {
    pushDataToDataLayer();
    setTimeout(() => {
        clearCartWithOrder();
      }, 1000);
  },[])
  return (
    <div className={styles.mainSecionOrder}>
      <div className={styles.orderBoxSection}>
        <ThumbUpIcon style={{ fontSize: 80 }} />
        {/* <h3>Thanks for choosing us.</h3> */}
        <h3>Order, placed Successfully.</h3>
        <p>We have sent you a comfirmation email. </p>
        <Link to="/" onClick={() => dispatch(setPlaceOrder(false))}>
          <button className={styles.orderButton}>Go, to home</button>
        </Link>
      </div>
    </div>
  );
};

export default OrderPlaced;
