import React from "react";

const SimpleLoader = () => {
  return (
    <div>
      <div class="spinner-border text-danger"></div>
    </div>
  );
};

export default SimpleLoader;
