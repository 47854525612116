import React from "react";
import styles from "./Styles.module.scss";
import { useGetProductByCategoryNameQuery } from "reduxStore/rtk";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Navbar from "components/Navbar/Navbar";
import Footer from "components/Footer/Footer";
import { CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
const Category = () => {
  const paramName = useParams();
  const { categoryName } = paramName;

  const { data, error, isLoading, isFetching, isSuccess, refetch } =
    useGetProductByCategoryNameQuery(categoryName);
  const [imageLoaded, setImageLoaded] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);

  // Determine the range of map data to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const records = allDataProduct && allDataProduct?.slice(indexOfFirstItem,indexOfLastItem);
  const nPage = Math.ceil(data && data?.length / itemsPerPage);
  // const number = [...Array(nPage + 1).keys()].slice(1);
  const currentMapData = data && data?.slice(indexOfFirstItem, indexOfLastItem);

  // Handle click event for next page button
  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  // Handle click event for previous page button
  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleImageLoad = (id) => {
    setImageLoaded((prev) => ({ ...prev, [id]: true }));
  };
  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <div className={styles.mainSection}>
        {isLoading ? (
          <div
            className="loader d-flex justify-content-center align-items-center"
            style={{ paddingTop: "20px", width: "100%", height: "100vh" }}
          >
            <CircularProgress color="inherit" />
          </div>
        ) : (
          <>
            <Navbar />
            <div className={styles.heroSection}>
              <h5>{categoryName}</h5>
              <h6>Category</h6>
            </div>
            <div className={`container-fluid ${styles.myContainer}`}>
              <div className={`row justify-content-center py-4`}>
                <div className="col-lg-12 text-center mt-4">
                  <h1>{`${categoryName}`}</h1>
                  <h6 className={styles.subHeadOfTop}>{`All Brands`}</h6>
                </div>
                <hr
                  style={{
                    margin: "10px 0px",
                    borderTop: "1px solid red",
                    opacity: "0.4",
                  }}
                />
                {isSuccess && data && data?.length === 0 ? (
                  <div className="col-lg-12 text-center">
                    <h1>No Product Available</h1>
                  </div>
                ) : (
                  currentMapData &&
                  currentMapData?.map((value, index) => {
                    return (
                      <div
                        className={`col-lg-3 col-md-6 col-sm-6 ${styles.myCol} py-3`}
                        key={index}
                      >
                        <Link to={{ pathname: `/product/${value.Id}` }}>
                          <div className={`${styles.cardContent} text-center`}>
                            <div
                              style={{
                                position: "relative",
                                width: "100%",
                                paddingTop: "100%", // Maintain aspect ratio
                                overflow: "hidden",
                              }}
                            >
                              {imageLoaded[value.Id] ? null : (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                                  }}
                                >
                                  <CircularProgress color="inherit" size={30} />
                                </div>
                              )}
                              <img
                                className={styles.cardContentIm}
                                src={`https://frontend.millatsports.com.pk${
                                  value && value?.Image
                                }`}
                                alt="Product"
                                onLoad={() => handleImageLoad(value.Id)}
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                  display: imageLoaded[value.Id]
                                    ? "block"
                                    : "none",
                                }}
                              />
                            </div>
                            <div className={`py-3`}>
                              <p
                                className={`${styles.textOnHover} ${styles.breakText}`}
                              >
                                {value.Name}
                              </p>
                              <p
                                className={`${styles.textOnHover} ${styles.breakText}`}
                              >
                                Rs. {value.Price}
                              </p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
            <div>
              {currentMapData && currentMapData?.length === 0 ? (
                <></>
              ) : (
                <div
                  className={
                    "d-flex justify-content-center align-items-center mt-2 mb-5"
                  }
                  style={{ width: "100%" }}
                >
                  {/* <p className="pb-3"><strong>View More Products</strong></p> */}
                  <button
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                    className={`${styles.paginationbutton} ${
                      currentPage === 1
                        ? `${styles.downOp}`
                        : `${styles.paginationbutton}`
                    }`}
                  >
                    <ArrowBackIcon />
                    previous
                  </button>
                  <p className="px-2">{`${currentPage} of ${nPage}`}</p>
                  <button
                    onClick={handleNextPage}
                    disabled={indexOfLastItem >= data?.length}
                    className={`${styles.paginationbutton} ${
                      indexOfLastItem >= data?.length
                        ? `${styles.downOp}`
                        : `${styles.paginationbutton}`
                    }`}
                  >
                    Next
                    <ArrowForwardIcon />
                  </button>
                </div>
              )}
            </div>

            <Footer />
          </>
        )}
      </div>
    </>
  );
};

export default Category;
