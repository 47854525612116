import React from "react";
import "./contactForm.css";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
import emailjs from "@emailjs/browser";
import { useEffect, useState } from "react";
import SimpleLoader from "components/SimpleLoader/SimpleLoader";
import { useFormik } from "formik";
import { useContactUsMutation } from "reduxStore/rtk";
import Alert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";
import * as Yup from "yup";

const ContactForm = () => {
  const [addContactDetails, response] = useContactUsMutation();
  const [showAlert, setShowAlert] = useState(false);
  const addHandler = async (value) => {
    await addContactDetails(value);
  };
  const formikk = useFormik({
    initialValues: {
      Name: "",
      Phone: "",
      From: "",
      Subject: "",
      Body: "",
    },
    onSubmit: (values) => {
      // emailjs.send('service_ca1z7p4', 'template_ilz5nod', values , 'pOLatEUyPSRq7E81I')
      //   .then((result) => {
      //     console.log(result.text);
      //   }, (error) => {
      //     console.log(error.text);
      //   });
      // console.log("ContactFormDetails:", values);
      addHandler(values);
      formikk.handleReset(values);
    },
    validationSchema: Yup.object({
      Name: Yup.string()
        .min(3, "Name must be between 3 to 30 charachters")
        .max(30, "Name must be between 3 to 30 charachters")
        .required("Required Field"),
      From: Yup.string()
        .email("Please enter a valid e-mail")
        .required("Required Field"),
      Subject: Yup.string().required("Required Field"),
      Body: Yup.string().required("Required Field"),
      Phone: Yup.string()
        .matches(/^[0-9]{11}$/, "Phone number must be exactly 11 digits")
        .required("Phone number is required"),
    }),
  });
  useEffect(() => {
    if (
      response &&
      response?.isLoading === false &&
      response?.isSuccess === true &&
      response?.status === "fulfilled"
    ) {
      setShowAlert(true);
    }
  }, [response]);
  return (
    <>
      <div className="content">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div className="row align-items-center">
                <div className="col-lg-7 mb-5 mb-lg-0">
                  <h2 className="mb-5 headings_form">
                    Fill the form. <br /> To Share Your Thoughts
                  </h2>
                  <form
                    className="border-right pr-5 mb-5"
                    method="post"
                    id="contactForm"
                    name="contactForm"
                    onSubmit={formikk.handleSubmit}
                  >
                    <div className="row">
                      {" "}
                      {/*full name*/}
                      <div className="col-md-6 form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="Name"
                          id="Name"
                          placeholder="Full name"
                          value={formikk.values.Name}
                          onChange={formikk.handleChange}
                          onBlur={formikk.handleBlur}
                        />
                        {formikk.errors.Name && formikk.touched.Name && (
                          <p style={{ color: "red", fontSize: "11px" }}>
                            {formikk.errors.Name}
                          </p>
                        )}
                      </div>
                      <div className="col-md-6 form-group">
                        <input
                          type="tel"
                          className="form-control"
                          name="Phone"
                          id="Phone"
                          placeholder="Phone Number"
                          value={formikk.values.Phone}
                          onChange={formikk.handleChange}
                          onBlur={formikk.handleBlur}
                        />
                        {formikk.errors.Phone && formikk.touched.Phone && (
                          <p style={{ color: "red", fontSize: "11px" }}>
                            {formikk.errors.Phone}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 form-group">
                        {/*email*/}
                        <input
                          type="email"
                          className="form-control"
                          name="From"
                          id="From"
                          placeholder="Email"
                          value={formikk.values.From}
                          onChange={formikk.handleChange}
                          onBlur={formikk.handleBlur}
                        />
                        {formikk.errors.From && formikk.touched.From && (
                          <p style={{ color: "red", fontSize: "11px" }}>
                            {formikk.errors.From}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 form-group">
                        {/*subject*/}
                        <input
                          type="text"
                          className="form-control"
                          name="Subject"
                          id="Subject"
                          placeholder="Subject"
                          value={formikk.values.Subject}
                          onChange={formikk.handleChange}
                          onBlur={formikk.handleBlur}
                        />
                        {formikk.errors.Subject && formikk.touched.Subject && (
                          <p style={{ color: "red", fontSize: "11px" }}>
                            {formikk.errors.Subject}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 my-2 form-group">
                        {" "}
                        {/*message*/}
                        <textarea
                          className="form-control text_area"
                          name="Body"
                          id="Body"
                          cols="30"
                          rows="7"
                          placeholder="Write your message"
                          value={formikk.values.Body}
                          onChange={formikk.handleChange}
                          onBlur={formikk.handleBlur}
                        ></textarea>
                        {formikk.errors.Body && formikk.touched.Body && (
                          <p style={{ color: "red", fontSize: "11px" }}>
                            {formikk.errors.Body}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      {response && response?.isLoading ? (
                        <div className="col-md-12 my-3">
                          <SimpleLoader />
                        </div>
                      ) : showAlert ? (
                        <div className="col-md-12 my-3">
                          <Alert
                            icon={<CheckIcon fontSize="inherit" />}
                            severity="success"
                          >
                            Form, has been submitted successfully. Soon you will
                            get a response.
                          </Alert>
                        </div>
                      ) : (
                        <div className="col-md-12 my-3">
                          <input
                            type="submit"
                            value="Send Message"
                            className=" btn btn-primary rounded-0 py-1 px-4"
                          />
                          <span className="submitting"></span>
                        </div>
                      )}
                    </div>
                  </form>
                  <div id="form-message-warning mt-4"></div>
                </div>
                <div className="col-lg-4 ml-auto">
                  <h3 className="mb-4 headings_form">
                    Let's talk about everything.
                  </h3>
                  {/* <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Nihil deleniti itaque similique magni. Magni, laboriosam
                    perferendis maxime!
                  </p> */}
                  {/* <p><a href="#">Read more</a></p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
