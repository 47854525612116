import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const initialState = {
  
  loginToken:{
    token: localStorage.getItem('loginToken') ,
  },
  showNavbar:true,
  isLogin: localStorage.getItem("isLogin"),
  placeOrder: false,
};

export const newUserSlice = createSlice({
  name: "newUserSlice",
  initialState,
  reducers: {
    
    setIsLogin: (state,action) => {
     state.isLogin = action.payload;
    },
    setLoginToken: (state,action) => {
      state.loginToken.token = action.payload;
    },
    setShowNavbar: (state,action) => {
      state.showNavbar = action.payload;
    },
    setPlaceOrder: (state,action) => {
      state.placeOrder = action.payload
    }
  },
});

export const {
  setLoginToken,
  setShowNavbar,
  setIsLogin,
  setPlaceOrder
} = newUserSlice.actions;

export default newUserSlice.reducer;
